/*
 * Common shipping form validation for offer pages.
 * Uses jQuery validate
 */

//Custom Validation Methods
$.validator.addMethod(
        'regex',
        function (value, element, regexp) {
                var re = new RegExp(regexp);
                return this.optional(element) || !value.match(re);
        },
        'Please enter valid input.'
);
$.validator.addMethod(
        'phoneUS',
        function (a, b) {
                return a = a.replace(/\s+/g, ''),
                        this.optional(b) || a.length > 9 && a.match(/^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/)
        },
        'Please specify a valid phone number'
);
//Additonal customer email validation to check that emails end at least with .xx
$.validator.methods.email = function (value, element) {
        return this.optional(element) || /\.(.{2,})/.test(value);
}

//Base Validation
$("#frontpage_form").validate({
        debug: false,
        submitHandler: function () {
            document.frontpage_form.submit();
        },
        errorClass: "invalid",
        validClass: "valid",
        rules: {
                cShipAddress1: {
                        regex: "@"
                },
                cShipZip: {
                        required: true,
                        number: true,
                        minlength: 5
                },
                cEmail: {
                        required: true,
                        email: true
                },
                cPhone2: {
                        required: true,
                        phoneUS: true
                }
        },
        messages: {
                cShipFname: "Please enter First Name",
                cShipLname: "Please enter Last Name",
                cShipAddress1: {
                        required: "Please enter Address",
                        regex: "Please enter valid mailing address (@ is not allowed)"
                },
                cShipCity: "Please enter City",
                province: "Please enter State",
                cShipZip: {
                        required: "Please enter Zip",
                        number: "Please enter valid Zip",
                        minlength: "Please enter 5 digit Zip"
                },
                cPhone2: {
                        required: "Please enter Phone Number",
                        phoneUS: "Please enter valid Phone Number: XXX-XXX-XXXX"
                },
                cEmail: {
                        required: "Please enter Email",
                        email: "Please enter a valid Email"
                }
        }
});
