// Footer Pop Out
function openPopUpUrl(e,t,a){return window.open(e,"","height="+t+", width="+a+", scrollbars=yes, resizable=yes");}

var zipAutofillInit = {
    'key':'AIzaSyAu_devqgFfFDvpYkTJimHzJVHiwEHjmYA',
    'selectors': '#frontpage_form',
    'country':'US',
    'citySelectAttr' : {
		'class': 'form-control',
        'required': true
    },
    'cityInputAttr': {
		'class': 'form-control',
        'type':'text',
        'required': true
    }
}

$('.scrollTo').click(function(){
	$('html, body').animate({scrollTop: $('.formBox').offset().top}, 'slow');
});

$(document).ready(function(){
	$( ".special" ).delay(3000).slideDown( "slow", function() {});
});
